
import React from 'react';
import { Input, Box, FormControl, Stack, InputGroup, InputLeftAddon, InputRightAddon, Pressable, Text, Icon } from 'native-base';

class DiceButton extends React.Component {
    render() {
      return (
        <Stack mb="3" alignItems={"center"} textAlign={"center"} marginRight={"0"}>
          <Box>{this.props.diceIcon}</Box>
          <FormControl.Label marginRight={"0"} textAlign={"center"}><Text marginRight={"0"}>{this.props.diceName}</Text></FormControl.Label>
          <InputGroup>
            <Pressable
              onPress={() => {
                this.props.setNDice(this.props.nDice - 1)
                this.props.setRoll(false)
                this.props.setD100(false)
              }}
            >
              <InputLeftAddon children={"-"} />
            </Pressable>
            <Input
              maxW={75}
              textAlign="center"
              value={this.props.nDice}
              onChangeText={text => {
                this.props.setNDice(text)
                this.props.setRoll(false)
                this.props.setD100(false)
              }}
              type="number" />
            <Pressable
              onPress={() => {
                this.props.setNDice(this.props.nDice + 1)
                this.props.setRoll(false)
                this.props.setD100(false)
              }}
            >
              <InputRightAddon children={"+"} />
            </Pressable>
          </InputGroup>
        </Stack>
      )
    }
  }

  export default DiceButton