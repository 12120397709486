import { Center, Icon } from "native-base";

const D6 = ({ size, colour }) => {
    return <Center>
        <Icon size={size} viewBox="0 0 812.67 812.67">
            <g>
                <g>
                    <g>
                        <g fill={colour} stroke={colour} strokeWidth={5}>
                            <rect class="d" width="812.67" height="812.67" rx="38.5" ry="38.5"/>
                        </g>
                    </g>
                </g>
            </g>
        </Icon>
    </Center>;
};
export default D6