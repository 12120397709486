import { Center, Icon } from "native-base";

const Success = ({ size, colour }) => {
    return <Center>
        <Icon size={size} viewBox="0 0 896.2 896.2">
            <g>
                <g>
                    <g>
                        <g fill={colour} stroke={colour} strokeWidth={5}>
                            <path d="M752.39,498.34c-29.77-26.88-29.77-73.6,0-100.48l141.67-127.9c4.51-4.07,1.41-11.54-4.65-11.23l-190.62,9.74c-40.06,2.05-73.1-30.99-71.05-71.05l9.74-190.62c.31-6.06-7.16-9.16-11.23-4.65l-127.9,141.67c-26.88,29.77-73.6,29.77-100.48,0L269.96,2.14c-4.07-4.51-11.54-1.41-11.23,4.65l9.74,190.62c2.05,40.06-30.99,73.1-71.05,71.05L6.8,258.73c-6.06-.31-9.16,7.16-4.65,11.23l141.67,127.9c29.77,26.88,29.77,73.6,0,100.48L2.14,626.24c-4.51,4.07-1.41,11.54,4.65,11.23l190.62-9.74c40.06-2.05,73.1,30.99,71.05,71.05l-9.74,190.62c-.31,6.06,7.16,9.16,11.23,4.65l127.9-141.67c26.88-29.77,73.6-29.77,100.48,0l127.9,141.67c4.07,4.51,11.54,1.41,11.23-4.65l-9.74-190.62c-2.05-40.06,30.99-73.1,71.05-71.05l190.62,9.74c6.06,.31,9.16-7.16,4.65-11.23l-141.67-127.9Zm-300.28,125.31c-97.32,0-176.22-78.9-176.22-176.22s78.9-176.22,176.22-176.22,176.22,78.9,176.22,176.22-78.9,176.22-176.22,176.22Z" />
                        </g>
                    </g>
                </g>
            </g>
        </Icon>
    </Center>;
};
export default Success