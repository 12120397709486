const blueDie = {
    0: {
      success: 0,
      advantage: 0,
    },
    1: {
      success: 0,
      advantage: 0,
    },
    2: {
      success: 1,
      advantage: 0,
    },
    3: {
      success: 0,
      advantage: 1,
    },
    4: {
      success: 0,
      advantage: 2
    },
    5: {
      success: 1,
      advantage: 1,
    }
  }

  const greenDie = {
    0: {
      success: 0,
      advantage: 0,
    },
    1: {
      success: 1,
      advantage: 0,
    },
    2: {
      success: 1,
      advantage: 0,
    },
    3: {
      success: 0,
      advantage: 1,
    },
    4: {
      success: 0,
      advantage: 1
    },
    5: {
      success: 1,
      advantage: 1,
    },
    6: {
      success: 0,
      advantage: 2,
    },
    7: {
      success: 2,
      advantage: 0,
    }
  }

  const yellowDie = {
    0: {
      success: 0,
      advantage: 0,
      triumph: 0
    },
    1: {
      success: 0,
      advantage: 0,
      triumph: 1,
    },
    2: {
      success: 1,
      advantage: 0,
      triumph: 0
    },
    3: {
      success: 1,
      advantage: 0,
      triumph: 0
    },
    4: {
      success: 0,
      advantage: 1,
      triumph: 0
    },
    5: {
      success: 1,
      advantage: 1,
      triumph: 0
    },
    6: {
      success: 1,
      advantage: 1,
      triumph: 0
    },
    7: {
      success: 1,
      advantage: 1,
      triumph: 0
    },
    8: {
      success: 2,
      advantage: 0,
      triumph: 0
    },
    9: {
      success: 2,
      advantage: 0,
      triumph: 0
    },
    10: {
      success: 0,
      advantage: 2,
      triumph: 0
    },
    11: {
      success: 0,
      advantage: 2,
      triumph: 0
    }
  }

  const blackDie = {
    0: {
      failure: 0,
      threat: 0,
    },
    1: {
      failure: 0,
      threat: 0,
    },
    2: {
      failure: 1,
      threat: 0,
    },
    3: {
      failure: 1,
      threat: 0,
    },
    4: {
      failure: 0,
      threat: 1,
    },
    5: {
      failure: 0,
      threat: 1,
    },
  }

  const purpleDie = {
    0: {
      failure: 0,
      threat: 0,
    },
    1: {
      failure: 1,
      threat: 0,
    },
    2: {
      failure: 0,
      threat: 1,
    },
    3: {
      failure: 0,
      threat: 1,
    },
    4: {
      failure: 0,
      threat: 1
    },
    5: {
      failure: 2,
      threat: 0,
    },
    6: {
      failure: 1,
      threat: 1,
    },
    7: {
      failure: 0,
      threat: 0,
    }
  }

  const redDie = {
    0: {
      failure: 0,
      threat: 0,
      despair: 0,
    },
    1: {
      failure: 0,
      threat: 0,
      despair: 1
    },
    2: {
      failure: 1,
      threat: 0,
      despair: 0
    },
    3: {
      failure: 1,
      threat: 0,
      despair: 0
    },
    4: {
      failure: 0,
      threat: 1,
      despair: 0
    },
    5: {
      failure: 0,
      threat: 1,
      despair: 0
    },
    6: {
      failure: 2,
      threat: 0,
      despair: 0
    },
    7: {
      failure: 2,
      threat: 0,
      despair: 0
    },
    8: {
      failure: 0,
      threat: 2,
      despair: 0
    },
    9: {
      failure: 0,
      threat: 2,
      despair: 0
    },
    10: {
      failure: 1,
      threat: 1,
      despair: 0
    },
    11: {
      failure: 1,
      threat: 1,
      despair: 0
    }
  }

  export {blueDie, greenDie, yellowDie, blackDie, purpleDie, redDie}