import { Center, Icon } from "native-base";

const Threat = ({ size, colour }) => {
    return <Center>
        <Icon size={size} viewBox="0 0 816.38 816.21">
            <g>
                <g>
                    <g>
                        <g fill={colour} stroke={colour} strokeWidth={5}>
                            <path d="M413.87,816.21c-47.9,0-94.38-9.39-138.14-27.9-42.26-17.88-80.21-43.46-112.8-76.05s-58.17-70.54-76.05-112.8c-18.51-43.76-27.9-90.24-27.9-138.14s9.39-94.38,27.9-138.14c17.88-42.26,43.46-80.21,76.05-112.8,32.59-32.59,70.54-58.17,112.8-76.05,43.76-18.51,90.24-27.9,138.14-27.9s94.38,9.39,138.14,27.9c42.26,17.88,80.21,43.46,112.8,76.05,32.59,32.59,58.17,70.54,76.05,112.8,18.51,43.76,27.9,90.24,27.9,138.14s-9.39,94.38-27.9,138.14c-17.88,42.26-43.46,80.21-76.05,112.8s-70.54,58.17-112.8,76.05c-43.76,18.51-90.24,27.9-138.14,27.9Zm0-670.77c-84.38,0-163.7,32.86-223.37,92.52-59.66,59.66-92.52,138.99-92.52,223.36s32.86,163.7,92.52,223.36,138.99,92.52,223.37,92.52,163.7-32.86,223.36-92.52,92.52-138.99,92.52-223.36-32.86-163.7-92.52-223.36c-59.66-59.66-138.99-92.52-223.36-92.52ZM431.61,9.36l63.95,111.01c3.38,5.86,3.32,13.1-.15,18.91l-68.18,114.17c-7.39,12.37-25.39,12.08-32.38-.52l-61.57-111.03c-3.18-5.73-3.12-12.71,.15-18.39L399.24,9.35c7.19-12.47,25.19-12.47,32.38,0Zm143.18,549.9l150.14-4.54c4.08-.12,7.91,1.97,10.01,5.47l79.81,133.14c4.47,7.46-.82,16.95-9.52,17.07l-148.86,2.06c-3.96,.05-7.65-1.97-9.74-5.33l-81.09-130.67c-4.58-7.38,.56-16.95,9.24-17.22Zm-323.97,15.76l-81.09,130.67c-2.09,3.36-5.78,5.38-9.74,5.33l-148.86-2.06c-8.69-.12-13.98-9.62-9.52-17.07l79.81-133.14c2.1-3.5,5.93-5.6,10.01-5.47l150.14,4.54c8.68,.26,13.82,9.83,9.24,17.22Z" />
                        </g>
                    </g>
                </g>
            </g>
        </Icon>
    </Center>;
};
export default Threat