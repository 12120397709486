import React, { useState } from 'react';
import DiceButton from './components/diceButton';
import { Button, HStack, NativeBaseProvider, Box, Center, Text, Heading, extendTheme } from 'native-base';
import { blueDie, greenDie, yellowDie, blackDie, purpleDie, redDie } from './components/dice';
import ChangeColorMode from './components/changeColorMode';
import DiceContainer from './components/diceContainer';
import Threat from './components/icons/threat';
import D12 from './components/icons/d12';
import D6 from './components/icons/d6';
import D10 from './components/icons/d10';
import Triumph from './components/icons/triumph';
import Advantage from './components/icons/advantage';
import Success from './components/icons/success';
import Despair from './components/icons/despair';
import Failure from './components/icons/failure';

const config = {
  useSystemColorMode: true,
  initialColorMode: "dark",
}

function App() {
  const customTheme = extendTheme({ config })
  const [triumph, setTriumph] = useState(0)
  const [success, setSuccess] = useState(0)
  const [advantage, setAdvantage] = useState(0)
  const [despair, setDespair] = useState(0)
  const [failure, setFailure] = useState(0)
  const [threat, setThreat] = useState(0)
  const [nYellow, setNYellow] = useState(0)
  const [nGreen, setNGreen] = useState(0)
  const [nBlue, setNBlue] = useState(0)
  const [nBlack, setNBlack] = useState(0)
  const [nPurple, setNPurple] = useState(0)
  const [nRed, setNRed] = useState(0)
  const [roll, setRoll] = useState(false)
  const [fullResult, setFullResult] = useState()
  const [d100, setD100] = useState()

  const displayTriumph = () => {
    let resultList = []
    for (var i = 0; i < triumph; i++) {
      resultList.push(<Box mx={0.5}><Triumph /></Box>)
    }
    return (
      <Box>
        <HStack>
          {resultList}
        </HStack>
      </Box>
    )
  }

  const displayThreat = () => {
    let resultList = []
    for (var i = 0; i < threat; i++) {
      resultList.push(<Box mx={0.5}><Threat /></Box>)
    }
    return (
      <Box>
        <HStack>
          {resultList}
        </HStack>
      </Box>
    )
  }

  const displaySuccess = () => {
    let resultList = []
    for (var i = 0; i < success; i++) {
      resultList.push(<Box mx={0.5}><Success /></Box>)
    }
    return (
      <Box>
        <HStack>
          {resultList}
        </HStack>
      </Box>
    )
  }

  const displayDespair = () => {
    let resultList = []
    for (var i = 0; i < despair; i++) {
      resultList.push(<Box mx={0.5}><Despair /></Box>)
    }
    return (
      <Box>
        <HStack>
          {resultList}
        </HStack>
      </Box>
    )
  }

  const displayAdvantage = () => {
    let resultList = []
    for (var i = 0; i < advantage; i++) {
      resultList.push(<Box mx={0.5}><Advantage /></Box>)
    }
    return (
      <Box>
        <HStack>
          {resultList}
        </HStack>
      </Box>
    )
  }

  const displayFailure = () => {
    let resultList = []
    for (var i = 0; i < failure; i++) {
      resultList.push(<Box mx={0.5}><Failure /></Box>)
    }
    return (
      <Box>
        <HStack>
          {resultList}
        </HStack>
      </Box>
    )
  }

  const rollBlue = () => {
    const roll = Math.floor(Math.random() * 6)
    //console.log(`blue: ${roll}`)
    const output = blueDie[roll]

    const result = {
      s: output.success,
      a: output.advantage
    }

    return result
  }

  const rollGreen = () => {
    const roll = Math.floor(Math.random() * 8)
    //console.log(`green: ${roll}`)
    const output = greenDie[roll]

    const result = {
      s: output.success,
      a: output.advantage
    }

    return result
  }

  const rollYellow = () => {
    const roll = Math.floor(Math.random() * 12)
    //console.log(`yellow: ${roll}`)
    const output = yellowDie[roll]

    const result = {
      s: output.success,
      a: output.advantage,
      tr: output.triumph
    }

    return result
  }

  const rollBlack = () => {
    const roll = Math.floor(Math.random() * 6)
    //console.log(`black: ${roll}`)
    const output = blackDie[roll]

    const result = {
      f: output.failure,
      t: output.threat
    }

    return result
  }

  const rollPurple = () => {
    const roll = Math.floor(Math.random() * 8)
    //console.log(`purple: ${roll}`)
    const output = purpleDie[roll]

    const result = {
      f: output.failure,
      t: output.threat
    }

    return result
  }

  const rollRed = () => {
    const roll = Math.floor(Math.random() * 12)
    //console.log(`red: ${roll}`)
    const output = redDie[roll]

    const result = {
      f: output.failure,
      t: output.threat,
      d: output.despair
    }

    return result
  }

  const roll100 = () => {
    const roll = Math.floor(Math.random() * 100)

    setD100(roll)
  }

  const rollDice = () => {
    let tr = 0
    let s = 0
    let a = 0
    let d = 0
    let f = 0
    let t = 0

    // loop blue rolls
    for (let i = 0; i < nBlue; i++) {
      const blueResult = rollBlue()
      s = s + blueResult.s
      a = a + blueResult.a
      //console.log(`blue roll ${i} stats. success: ${blueResult.s}, advantage: ${blueResult.a}`)
    }

    // loop green rolls
    for (let i = 0; i < nGreen; i++) {
      const greenResult = rollGreen()
      s = s + greenResult.s
      a = a + greenResult.a
      //console.log(`green roll ${i} stats. success: ${greenResult.s}, advantage: ${greenResult.a}`)
    }

    // loop yellow rolls
    for (let i = 0; i < nYellow; i++) {
      const yellowResult = rollYellow()
      tr = tr + yellowResult.tr
      s = s + yellowResult.s
      a = a + yellowResult.a
      //console.log(`yellow roll ${i} stats. triumph: ${yellowResult.tr}, success: ${yellowResult.s}, advantage: ${yellowResult.a}`)
    }

    //loop black rolls
    for (let i = 0; i < nBlack; i++) {
      const blackResult = rollBlack()
      f = f + blackResult.f
      t = t + blackResult.t
      //console.log(`black roll ${i} stats. failure: ${blackResult.f}, threat: ${blackResult.t}`)
    }

    //loop purple rolls
    for (let i = 0; i < nPurple; i++) {
      const purpleResult = rollPurple()
      f = f + purpleResult.f
      t = t + purpleResult.t
      //console.log(`purple roll ${i} stats. failure: ${purpleResult.f}, threat: ${purpleResult.t}`)
    }

    //loop red rolls
    for (let i = 0; i < nRed; i++) {
      const redResult = rollRed()
      f = f + redResult.f
      t = t + redResult.t
      d = d + redResult.d
      //console.log(`red roll ${i} stats. despair: ${redResult.d}, failure: ${redResult.f}, threat: ${redResult.t}`)
    }

    setTriumph(tr - d)
    setSuccess(s - f)
    setAdvantage(a - t)
    setDespair(d - tr)
    setFailure(f - s)
    setThreat(t - a)
    setFullResult(`The dice rolled ${tr} triumphs, ${s} success, ${a} advantage, ${d} despair, ${f} failure and ${t} threat`)
    setRoll(true)
  }

  return (
    <NativeBaseProvider theme={customTheme}>
      <DiceContainer>
        <Box flex="1" flexDirection="row" justifyContent="space-between" p="5" mb="5" width="100%">
          <Heading display="flex" >
            FFG Dice Roller
          </Heading>
          <ChangeColorMode />
        </Box>
        <Center flex="1" flexDirection="column" justifyContent="center">
          <Heading mb={8}>Roll Setup</Heading>
          <Box flex="1" flexDirection="row" mb="5" justifyContent="center">
            <Box display="flex" m="1" flexDirection="column" alignItems={"center"}>
              <DiceButton setNDice={setNBlue}
                nDice={nBlue}
                diceName={"Bonus"}
                diceIcon={<D6 colour={'#72c6dc'} />} setRoll={setRoll} setD100={setD100} />
              <DiceButton setNDice={setNGreen} nDice={nGreen} diceName={"Ability"} diceIcon={<D10 colour={'#2b7042'} />} setRoll={setRoll} setD100={setD100} />
              <DiceButton setNDice={setNYellow} nDice={nYellow} diceName={`Proficiency`} diceIcon={<D12 colour={'#e2dd75'} />} setRoll={setRoll} setD100={setD100} />
            </Box>
            <Box display="flex" m="1" flexDirection="column" alignItems={"center"}>
              <DiceButton setNDice={setNBlack} nDice={nBlack} diceName={"Setback"} diceIcon={<D6 colour={'#3a3a3a'} />} setRoll={setRoll} setD100={setD100} />
              <DiceButton setNDice={setNPurple} nDice={nPurple} diceName={"Difficulty"} diceIcon={<D10 colour={'#7a5d9c'} />} setRoll={setRoll} setD100={setD100} />
              <DiceButton setNDice={setNRed} nDice={nRed} diceName={`Enemy Proficiency`} diceIcon={<D12 colour={'#942525'} />} setRoll={setRoll} setD100={setD100} />
            </Box>
          </Box>
        </Center>
        <Box alignItems="center">
          <HStack>
          <Button mx={2} onPress={rollDice}>Roll Dice</Button> <Button mx={2} onPress={roll100}>Roll D100</Button>
          </HStack>
        </Box>
        <Center mt="5" flex="1" flexDirection="column" justifyContent="center">
          {d100 &&
            <>
              <Heading>D100 Summary</Heading>
              <Box flex="1" m="5" flexDirection="row">
                <Text>
                  Your d100 roll is {d100}
                </Text>
              </Box>
            </>
          }
          {roll &&
            <>
              <Heading mt={8}>Roll Outcome</Heading>
              <HStack my={5}>
                {displayTriumph()}
                {displaySuccess()}
                {displayAdvantage()}
                {displayDespair()}
                {displayFailure()}
                {displayThreat()}
                {(threat === 0 && failure === 0 && despair === 0 && advantage === 0 && success === 0 && triumph === 0) &&
                  <Box display="flex" m="5" flexDirection="column" >
                    <Text textAlign="center">
                      Your roll is a wash!
                    </Text>
                  </Box>
                }
              </HStack>
              <Heading>Roll Summary</Heading>
              <Box flex="1" flexDirection="row">
                {(advantage > 0 || success > 0 || triumph > 0) &&
                  <Box display="flex" m="5" flexDirection="column" >
                    {triumph > 0 &&
                      <Text>
                        Triumph: {triumph}
                      </Text>
                    }
                    {success > 0 &&
                      <Text>
                        Success: {success}
                      </Text>
                    }
                    {advantage > 0 &&
                      <Text>
                        Advantage: {advantage}
                      </Text>
                    }
                  </Box>
                }
                {(threat > 0 || failure > 0 || despair > 0) &&
                  <Box display="flex" m="5" flexDirection="column" >
                    {despair > 0 &&
                      <Text>
                        Despair: {despair}
                      </Text>
                    }
                    {failure > 0 &&
                      <Text>
                        Failure: {failure}
                      </Text>
                    }
                    {threat > 0 &&
                      <Text>
                        Threat: {threat}
                      </Text>
                    }
                  </Box>
                }
                {(threat === 0 && failure === 0 && despair === 0 && advantage === 0 && success === 0 && triumph === 0) &&
                  <Box display="flex" m="5" flexDirection="column" >
                    <Text textAlign="center">
                      Your roll is a wash!
                    </Text>
                  </Box>
                }
              </Box>
            </>
          }
          {roll &&
            <>
              <Box px="5">
                <Text>{fullResult}</Text>
              </Box>
              <Box flex="1" flexDirection="row">
                <Box display="flex" m="5" flexDirection="column" >
                  <Text>
                    You rolled {nBlue} blue dice.
                  </Text>
                  <Text>
                    You rolled {nGreen} green dice.
                  </Text>
                  <Text>
                    You rolled {nYellow} yellow dice.
                  </Text>
                </Box>
                <Box display="flex" m="5" flexDirection="column" >
                  <Text>
                    You rolled {nBlack} black dice.
                  </Text>
                  <Text>
                    You rolled {nPurple} purple dice.
                  </Text>
                  <Text>
                    You rolled {nRed} red dice.
                  </Text>
                </Box>
              </Box>
            </>
          }
        </Center>
      </DiceContainer>
    </NativeBaseProvider>
  );
}

export default App;
