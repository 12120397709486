import { Center, Icon } from "native-base";

const Advantage = ({ size, colour }) => {
    return <Center>
        <Icon size={size} viewBox="0 0 873.07 748.09">
            <g>
                <g>
                    <g>
                        <g fill={colour} stroke={colour} strokeWidth={5}>
                            <path d="M462.27,460.46l395.98,285.79c8.13,5.87,18.51-3.51,13.5-12.19l-206.75-358.11L453.64,9.88c-7.6-13.17-26.62-13.17-34.22,0L208.07,375.96,1.31,734.07c-5.01,8.68,5.37,18.06,13.5,12.19L410.79,460.46c15.37-11.09,36.11-11.09,51.48,0Z" />
                        </g>
                    </g>
                </g>
            </g>
        </Icon>
    </Center>;
};
export default Advantage