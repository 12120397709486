import React from 'react';
import { useColorMode, Flex } from 'native-base';


function DiceContainer({ children }) {
    const { colorMode } = useColorMode()
    const height = window.innerHeight
    return (
      <Flex minH={height} bg={colorMode === "light" ? "light.50" : "light.800"}>
        {children}
      </Flex>
    )
  }

  export default DiceContainer