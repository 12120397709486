import { Center, Icon } from "native-base";

const D10 = ({ size, colour }) => {
    return <Center>
        <Icon size={size} viewBox="0 0 905.24 788.22">
            <g>
                <g>
                    <g>
                        <g fill={colour} stroke={colour} strokeWidth={5}>
                            <path d="M438.73,8.02L2.17,764.16c-6.17,10.69,1.54,24.06,13.89,24.06H889.18c12.35,0,20.06-13.37,13.89-24.06L466.51,8.02c-6.17-10.69-21.61-10.69-27.78,0Z" />
                        </g>
                    </g>
                </g>
            </g>
        </Icon>
    </Center>;
};
export default D10