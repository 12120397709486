import React from 'react';
import { Pressable, Text, useColorMode, HStack } from 'native-base';

function ChangeColorMode() {
    const { colorMode, toggleColorMode } = useColorMode()
    return (
      <HStack>
        <Pressable onPress={toggleColorMode}>
          <Text>Toggle {colorMode} mode</Text>
        </Pressable>
      </HStack>
    )
  }

export default ChangeColorMode