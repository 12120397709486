import { Center, Icon } from "native-base";

const D12 = ({ size, colour }) => {
    return <Center>
        <Icon size={size} viewBox="0 0 903.74 855.6">
            <g>
                <g>
                    <g>
                        <g fill={colour} stroke={colour} strokeWidth={5}>
                            <path d="M424.16,8.93L19.76,299.72c-16.81,12.08-23.84,33.67-17.38,53.34l154.27,469.89c6.4,19.48,24.58,32.66,45.09,32.66h500.27c20.51,0,38.7-13.17,45.09-32.66l154.27-469.89c6.46-19.67-.58-41.25-17.38-53.34L479.58,8.93c-16.55-11.9-38.86-11.9-55.41,0Z" />
                        </g>
                    </g>
                </g>
            </g>
        </Icon>
    </Center>;
};
export default D12