import { Center, Icon } from "native-base";

const Despair = ({ size, colour }) => {
    return <Center>
        <Icon size={size} viewBox="0 0 901.62 907.13">
            <g>
                <g>
                    <g>
                        <g fill={colour} stroke={colour} strokeWidth={5}>
                            <path d="M452.44,831.77c-50.78,0-100.06-9.95-146.46-29.58-44.81-18.95-85.04-46.08-119.59-80.62s-61.67-74.78-80.62-119.59c-19.62-46.4-29.58-95.67-29.58-146.46s9.95-100.06,29.58-146.46c18.95-44.81,46.08-85.04,80.62-119.59,34.55-34.55,74.78-61.67,119.59-80.62,46.4-19.62,95.67-29.58,146.46-29.58s100.06,9.95,146.46,29.58c44.81,18.95,85.04,46.08,119.59,80.62s61.67,74.78,80.62,119.59c19.62,46.4,29.58,95.67,29.58,146.46s-9.95,100.06-29.58,146.46c-18.95,44.8-46.08,85.04-80.62,119.59s-74.78,61.67-119.59,80.62c-46.4,19.62-95.67,29.58-146.46,29.58Zm0-711.14c-89.45,0-173.55,34.83-236.81,98.09s-98.09,147.35-98.09,236.81,34.83,173.55,98.09,236.81,147.35,98.09,236.81,98.09,173.55-34.83,236.81-98.09,98.09-147.35,98.09-236.81-34.83-173.55-98.09-236.81-147.35-98.09-236.81-98.09Zm133.22-16.08H319.9L451.97,0l133.69,104.54ZM318.49,802.58h265.76l-132.07,104.54-133.69-104.54ZM104.54,322.87v265.76L0,456.56l104.54-133.69Zm797.07,134.07l-104.54,132.07V323.25l104.54,133.69Zm-451.83-113.73l290.89-192.29c8.08-5.34,17.65,4.26,12.3,12.32l-193.01,290.92c-1.97,2.98-1.97,6.85,0,9.82l192.87,290.71c5.36,8.08-4.25,17.67-12.32,12.31l-290.72-193.11c-2.98-1.98-6.85-1.98-9.83,0l-290.51,192.97c-8.08,5.37-17.69-4.25-12.31-12.33l193.69-290.53c1.99-2.98,1.99-6.87,0-9.86L137.02,163.39c-5.38-8.06,4.21-17.68,12.29-12.34l290.68,192.16c2.97,1.96,6.83,1.96,9.8,0Z" />
                        </g>
                    </g>
                </g>
            </g>
        </Icon>
    </Center>;
};
export default Despair