import { Center, Icon } from "native-base";

const Failure = ({ size, colour }) => {
    return <Center>
        <Icon size={size} viewBox="0 0 828.81 828.95">
            <g>
                <g>
                    <g>
                        <g fill={colour} stroke={colour} strokeWidth={5}>
                            <path d="M420.83,259.51L810.27,2.07c10.81-7.15,23.63,5.7,16.47,16.5l-258.4,389.48c-2.64,3.98-2.64,9.17,0,13.15l258.22,389.2c7.17,10.81-5.68,23.66-16.49,16.48l-389.21-258.54c-3.99-2.65-9.17-2.65-13.16,0L18.76,826.69c-10.82,7.19-23.68-5.7-16.48-16.5L261.59,421.21c2.66-4,2.66-9.2,0-13.19L2.09,18.77C-5.11,7.97,7.72-4.9,18.55,2.25L407.71,259.51c3.98,2.63,9.14,2.63,13.12,0Z" />
                        </g>
                    </g>
                </g>
            </g>
        </Icon>
    </Center>;
};
export default Failure